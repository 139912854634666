.login {
  position: relative;
  padding-top: 40px;
}

.login .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login .form-div {
  text-align: left;
  background-color: #fff;
  box-shadow: 4px 4px 40px 20px rgba(36, 43, 39, 0.06);
  border-radius: 10px;
  padding: 30px 10px;
  text-align: center;
}

.form-div .sub_title {
  max-width: 35ch;
  text-align: center;
}

.login .login-btn {
  width: 100%;
  background-color: var(--main-danger-color);
  border: none !important;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 40px;
  font-weight: bold;
  color: white;
  transition: ease-in-out 0.3s;
  margin-top: 20px;
}

.login .login-btn:hover {
  background-color: #ff9d7f;
  color: #e5e5e5;
  box-shadow: 4px 4px 4px 10px rgba(36, 43, 39, 0.06);
}

.login .ant-col-24-.ant-form-item-label {
  padding: 0 !important;
}

.login .ant-input-affix-wrapper,
.login #login_email {
  height: 40px;
  background-color: #f4f4f4 !important;
  border-radius: 10px;
}

.login .login_password {
  margin-bottom: 0 !important;
}

.login .forgot {
  padding-inline: 10px;
  margin: 10px 0;
  display: block;
  text-align: end;
}

.login__help {
  text-align: center;
  margin-top: 20px;
  max-width: 20ch;
}

.btn--login {
  background-color: red !important;
  margin-block: 20px !important;
  width: 100%;
  color: white !important;
}

@media (min-width: 640px) {
  .login .form-div {
    width: 380px;
    padding-inline: 30px;
  }

  .login__help {
    max-width: unset;
  }
}

.login .ant-input {
  background-color: #f4f4f4 !important;
}

.login .form-div > h3 {
  font-weight: bold;
}
