.signup {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.signup__wrapper {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin-inline: auto;
  padding-inline: 1rem;
}

/* .signup .progressive {
  display: flex;
  align-self: center;
  list-style: none;
}

.signup .progress-items {
  margin: 0 10px;
  align-items: center;
  display: flex;
  color: #242;
}

.signup .progress-items.active {
  color: green;
}

.signup .anticon {
  margin: 0 3px;
}

.signup .ant-form-item-control-input-content {
  text-align: right;
}

.signup .minority .ant-form-item-control-input-content {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.signup .minority .ant-form-item-control-input-content span {
  text-align: left;
}

.signup .minority .ant-form-item-control-input-content .ant-checkbox-wrapper {
  margin: 0;
}

.signup .container {
  display: flex;
  flex-direction: column;
}

.signup .ant-input {
  background-color: #f4f4f4;
}

.signup .ant-input-affix-wrapper {
  height: 40px;
  background-color: #f4f4f4 !important;
  border-radius: 10px;
}

.signup .ant-form-item-label {
  padding: 0;
}
*/

.form__account-type .signup-btn {
  background-color: var(--main-danger-color);
  border: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 40px;
  font-weight: bold;
  color: white;
  transition: ease-in-out 0.3s;
  margin-top: 20px;
}

.form__account-type .signup-btn:hover {
  background-color: #ff9d7f;
  color: #e5e5e5;
  box-shadow: 4px 4px 4px 10px rgba(36, 43, 39, 0.06);
}

.signup .ant-space-item {
  height: 40px;
}

.signup .back {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 40px;
  font-weight: bold;
  transition: ease-in-out 0.3s;
  margin-top: 20px;
}

.signup .back:hover {
  color: var(--main-danger-color);
  box-shadow: 4px 4px 4px 10px rgba(36, 43, 39, 0.06);
  border: rgba(36, 43, 39, 0.06) solid 1px;
}

/* .signup .form-div {
  max-width: 380px;
  text-align: left;
  background-color: #fff;
  box-shadow: 4px 4px 40px 20px rgba(36, 43, 39, 0.06);
  border-radius: 10px;
  padding: 30px;
  align-self: center;
}

.signup .progress-items {
  white-space: nowrap;
}
.signup .progressive {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-bottom: 20px;
} */

.terms-btn {
  cursor: pointer;
  font-weight: 700;
}

.cause-select .ant-select-selector {
  background-color: transparent !important;
}

.ant-modal .ant-modal-content {
  height: 100%;
}

@media screen and (max-width: 480px) {
  .signup .progress-items {
    font-size: 9px;
  }
}

/* customize steps for signup */
.signup_content {
}

.signup_steps .anticon {
  display: inline-flex;
}
.signup_steps .ant-steps-item-container {
  height: 80px;
}

.signup_steps .ant-steps-item {
  min-width: 250px;
}

.btn--signup {
  margin-top: 2em;
  /* margin-left: 70%; */
  width: 80px;
}

.signup_content {
  margin-top: 30px;
  align-self: flex-start;
  padding-inline-start: 70px;
  width: calc(100% - 2rem);
}
