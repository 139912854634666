/* reset input form to match the need on the subscribe form */
.subscription__form .input_field__wrapper {
  padding: 0;
  flex: auto;
}
.subscription__form .input_field__wrapper .input_field {
  border: none;
  border-radius: 0;
}

.subscription__form {
  display: flex;
  place-content: center;
  margin: 15px auto;
}

.subscription__form .btn--subscription {
  background-color: cornflowerblue !important;
  color: white !important;
  border-radius: 0 !important;
  min-width: 100px;
  max-width: 100px;
}

.subscribe__content--wrapper {
  text-align: center;
  padding: 10px;
  background-color: #cbdbfd;
}

.subscribe__content {
  max-width: 45ch;
  margin: 20px auto;
}
