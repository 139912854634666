.btn__cart--add,
.btn__cart--remove,
.btn__cart--view,
.btn__cart--continue {
  font-weight: 500;
  background-color: #059669 !important;
  margin-top: 20px;

  color: #f9f9f9 !important;
}

.btn__cart--add,
.btn__cart--remove {
  width: 100% !important;
}

.btn__cart--view,
.btn__cart--continue {
  min-width: max-content;
  width: 40% !important;

}

.btn__cart--view:first-child,
.btn__cart--continue {
  margin-right: 1rem;

}

.btn-cart-container{
  display: flex;
  justify-content: space-between;
}

.btn__cart--remove {
  background-color: red !important;
  color: white !important;
}

.btn__cart--view {
  background-color: green !important;
  color: white !important;
}

.btn__cart--continue {
  background-color: blue !important;
  color: white !important;
}
