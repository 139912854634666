:root {
  --main-danger-color: #ff4c4f;
  --main-primary-dark-color: #242a27;
  --main-primary-background: #e5e5e5;
  --main-primary-color: #242b27;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-style: normal;
  color: var(--main-primary-color);
}

.responsive-img {
  width: 100%;
  height: auto;
}

@media (max-width: 575px) {
  .container {
    --bs-gutter-x: 2.5rem !important;
  }
}

/* reset the following pages to cover full screen */
.ant-layout-content,
.login,
.signup__wrapper {
  min-height: 100dvh;
}

.ant-layout-content {
  min-height: 100dvh !important;
}

/* loder styles */
.loader__wrapper {
  display: grid;
  place-content: center;

  height: calc(100dvh - 100px);
}

/* reset card padding */

.ant-picker {
  border: 2px solid #ece5e5a6 !important;
  border-radius: 6px !important;
}
.ant-card .ant-card-bordered .ant-card-body {
  padding: 0.5rem !important;
}

/* reset date-picker styles */
.ant-picker-input > input {
  font-size: 1rem !important;
  height: 2.5rem !important;
}

.ant-picker .ant-picker-focused .ant-picker-input > input {
  border-color: var(--main-primary-color) !important;
}

.ant-picker-input > input:hover {
  border-color: var(--main-primary-color) !important;
}

.ant-picker-input > input:focus {
  border-color: var(--main-primary-color) !important;
}

.ant-picker-input > input::placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input::-moz-placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:-ms-input-placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input::-webkit-input-placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled {
  border-color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled::placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled::-moz-placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled:-ms-input-placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled::-webkit-input-placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled {
  border-color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled::placeholder {
  color: var(--main-primary-color) !important;
}

.ant-picker-input > input:disabled::-moz-placeholder {
  color: var(--main-primary-color) !important;
}

/* hide ant-picker-suffix icon */
.ant-picker-suffix {
  display: none !important;
}

/* reset ant qrcode styles */

.ant-qrcode {
  width: 100% !important;
  height: 300px !important;
}

.card-promo {
  min-height: max-content;
  height: 194px;
}

.text-base {
  font-size: 400;
}

.text-black-200 {
  color: #5b5b5b;
}

.lowercase {
  text-transform: lowercase;
}

.pad-2 {
  padding: 0.4rem 0.5rem;
  font-size: 14px;
  color: #5b5b5b;
  font-weight: 500;
}

.space-y-4 {
  row-gap: 1rem;
}