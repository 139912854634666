.program-list {
  background-color: #b2dafb;
  padding: 40px 0;
  min-height: 100dvh;
}

.program-list .topper {
  width: 100%;
  margin-bottom: 20px;
}

.program-list .topper h3 {
  text-transform: capitalize;
  font-weight: bold;
  color: black;
  text-align: center;
}

.program-list .topper h4 {
  font-size: 18px;
  text-transform: capitalize;
  text-align: center;
}

.program-list .topper > a {
  color: var(--main-danger-color);
  border: var(--main-danger-color) 1px solid;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: 0.3s ease-in;
}

.program-list .topper > a:hover {
  background-color: white;
}

.program-list .col {
  margin-bottom: 40px;
}

.program-list .card {
  box-shadow: 2px 2px 5px 2px rgba(194, 181, 181, 0.342);
  -webkit-box-shadow: 2px 2px 5px 2px rgba(194, 181, 181, 0.342);
  -moz-box-shadow: 2px 2px 5px 2px rgba(194, 181, 181, 0.342);
  position: relative;
}

.program-list .card .flag {
  position: absolute;
  right: 0%;
  bottom: 25px;
}

.program-list .card-body {
  padding: 5px 10px 14px;
  background-color: #f0efef;
}

.program-list a {
  color: #111111;
}

.program-list .card h4 {
  color: rgb(49, 49, 49);
  font-weight: 500;
  font-size: 18px;
  margin: 0px 0px 5px;
}

.program-list .total-discount {
  color: rgb(49, 49, 49);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}

.program-list .total-discount span {
  font-weight: lighter;
  color: #6c757d;
  font-size: 14px;
  font-style: italic;
  text-decoration: none;
}

.btn--cart {
  width: 100% !important;
  padding: 0 !important;
  height: 50px;
  border-radius: 0 !important;
}

.program__avatar {
  height: 300px;
}

.card--color {
  background-color: #f0efef !important;
}

.program-list .total-price {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  color: rgb(88, 88, 88);
  text-decoration: line-through;
}
