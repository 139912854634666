/* Programs styles */
.programs_wrapper {
  margin-top: 30px;
  padding: 20px;
  min-height: 100dvh;
}

/* Page Title */
.programs__title {
  text-align: center;

  font-size: 1.5rem;
  line-height: 1.8;
  font-weight: 500;
  margin-block: 1rem;
}

.program__link {
  text-decoration: none;
  color: black;
}

.program__link:hover {
  color: rgba(0, 0, 0, 0.926);
  text-decoration: none;
}

/* Page content */
.programs__content {
  /* background-color: white; */
  padding: 10px;
}

.programs__content .program__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.no-data {
  margin: auto;
  height: 400px;
  display: grid;
  place-content: center;
}

/* program item image */
.program__item {
  flex: auto;

  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  position: relative;
}

.program__item:hover {
  padding: 10px 10px;
  background-color: #fff;
  transition: all 300ms ease-in;
}

.program__content .program__title {
  line-height: 2;
  font-weight: 600;
}

.program__item .program__item--image img {
  height: 210px;
  width: 100%;
  margin-block: 10px;
}

@media (min-width: 640px) {
  .program__item {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .program__item {
    max-width: 400px;
  }
}

/* medium-sized tablet */
@media (min-width: 768px) {
  .program__item {
    width: 400px;
  }
}

@media (min-width: 1024px) {
  .program__item .program__item--image img {
    height: 310px;
  }

  .programs__content .program__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin:0 1rem;
  }
}

.program__item .program__row {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-block: 10px;
}

.program__item .program__content {
  padding-inline: 20px;
}

.program__item .bold,
.program__item .price {
  font-size: 1.1rem;
  line-height: 1.7;
}

.program__item .discount {
  position: absolute;
  top: 2rem;
  right: 1.6rem;
  padding: 6px 12px;
  font-size: 1.2rem;
}

/* Actions */
/* Buttons */
.btn {
  padding: 10px 20px;
}

.btn:hover {
  opacity: 0.9;
}

.program__item .add_to_cart,
.program__item .add_to_cart:hover,
.program__item .remove_from_cart {
  font-weight: 500;
  background-color: #059669;
  width: 100%;
  margin-top: 20px;

  color: #f9f9f9;
}

.program__item .remove_from_cart,
.program__item .remove_from_cart:hover {
  background-color: red;
  color: white;
}
