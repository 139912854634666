.wishlist_container {
  padding: 0px 20px;
  min-height: calc(100dvh - 100px);
}


.wishlist_container .no_wishlist{
  background-color: green!important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  place-content: center;

  text-align: center;

  max-width: 45ch;
  margin: auto 0;
}

.input_field {
  border-left: 0px;
  border-right: 1px solid rgb(160, 160, 160);
  border-top: 0px;
  border-bottom: 1px solid rgb(160, 160, 160);
  border-radius: 6px;
  width: 90%;
  padding: 10px;
  font-size: 14px;
}

.input_field::placeholder {
  font-size: 12px;
  font-weight: 500;
}

.label_form {
  font-size: 12px;
  font-weight: 500;
}