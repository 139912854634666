a {
  color: #ff4c4f;
}
.auth-header {
  background-color: transparent;
  padding: 10px;
  margin-bottom: 50px;
  width: 100%;
}

.auth-header .container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
}

.auth-header .header {
  margin-bottom: 10px;
}

.auth-header .header img {
  width: 100px;
  height: 50px;
}
