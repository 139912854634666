.wishlist-container {
  padding: 0px 20px;
}

.cart__icon {
  width: 100px;
  aspect-ratio: 1;

  margin-inline: auto;
}

.no_cart__wrapper {
  display: flex;

  flex-direction: column;
  gap: 20px;

  margin-top: calc(25dvh - 100px);
}

.no_cart {
  max-width: 45ch;
  text-align: center;
  margin-inline: auto;
  margin-top: 10%;

  font-size: 24px;
  line-height: 1.8;
  font-weight: 500;
}

/* cart */
.payment-container h3 {
  color: #282828;
  font-weight: 500;
}

.payment-container .detail h4 {
  text-transform: capitalize;
  font-size: 18px;
  color: #282828;
}

.payment-container .detail h4 span {
  font-weight: 400;
  color: #000000;
}

.payment-success,
.payment-error {
  font-size: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode__wrapper {
  margin-top: 20px;
  width: 100%;
}

/* qrcode list */
.qrcode__list {
  display: grid;
  gap: 15px;
  margin-block: 10px;
}

/* mid tablet */
@media (min-width: 768px) {
  .qrcode__list {
    grid-template-columns: repeat(2, minmax(500px, 1fr));
  }
}

/* large tablet */
@media (min-width: 992px) {
  .qrcode__list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* desktop */
@media (min-width: 1219px) {
  .qrcode__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
/* qrcode item */
.qrcode__item {
  background-color: #efeeee;
  padding: 20px 10px;
  border-radius: 5px;
}

.qrcode__item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  text-transform: capitalize;
}

.qrcode__item p {
  font-size: 15px;
}

.qrcode__item button {
  margin-top: 10px;
}

.check-icon {
  color: #007bff;
  font-size: 20px;
  margin-left: 5px;
}

.qrCode__page {
  padding-inline: 15px;
  padding-bottom: 20px;
}

.qrCode__page h3 {
  text-align: center;
}
