.program__card {
  background-color: white;
  padding: 8px;
  border-radius: 10px;
  box-shadow: inset;
}

.btn--cart {
  margin-top: 1rem;
}
