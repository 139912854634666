.hero {
  background: #fff;
  margin-top: -80px;
  padding-top: 150px;
  padding-bottom: 100px;
  background-attachment: fixed;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hero .container {
  position: relative;
}

.hero .hero-text-container {
  color: #5c5758;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.hero .hero-text-container h1 {
  color: #000;
  font-size: 42px;
  line-height: 1.2;
}

.hero .hero-img {
  overflow: hidden;
  margin-top: -50px;
}
.hero .hero-img-container {
  max-height: 400px;
}

@media (max-width: 991px) {
  .hero .hero-text-container h1 {
    color: #000;
    font-size: 34px;
  }
}

@media (max-width: 767px) {
  .hero {
    padding-top: 120px;
  }
}
