/* @import "~antd/dist/antd.css"; */
@import "~bootstrap/dist/css/bootstrap.min.css";

input:focus {
  outline: none;
}

/* error */
.error__card {
  color: whitesmoke;
  background-color: rgba(255, 0, 0, 0.552);
  padding: 10px 30px;
  text-align: center;

  font-size: 16px;
  font-weight: 500;
}
