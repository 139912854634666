.input_field__wrapper {
  padding: 10px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  text-align: start;
}

.input_field__wrapper--horizontal {
  flex-direction: row;
  align-items: center;
}

.input_field__wrapper .input_field {
  padding: 5px;
  border: 2px solid #ece5e5a6;
  flex: auto;
  height: 40px;
}
.input_field__wrapper .input_field--error {
  border-color: red;
  color: red;
}

.password__wrapper {
  position: relative;
}

/* should not show the toggle button if not a password field */
.input_field__wrapper button {
  display: none;
}

.password__wrapper button {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;

  border: none;
  padding: 5px;
  display: grid;
  width: 53px;
}

.is_required {
  color: red;
}

.error_message {
  color: red;
}

/* Select Input */

.select__input {
  width: 100%;
  border: 2px solid #ece5e5a6;
  outline: none;
  padding: 10px;
  border-radius: 3%;
  margin-block: 10px;
}

.select__input:focus,
.select__input:hover,
.select__input::selection {
  border: 2px solid #ece5e5a6;
  cursor: pointer;
}

.select__wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;

  margin-block: 10px;
  padding: 10px;
}
.select__label {
  text-align: center;
}
