.program-list-container {
  padding: 0px 20px;
}

.table thead {
  background-color: #242b27;
  color: rgb(241, 240, 240);
}

.table thead tr th {
  padding: 15px 4px;
}

.table tbody {
  font-size: 15px;
  text-transform: capitalize;
}

.borderless td,
.borderless th {
  padding: 20px 0px;
}

.borderless tr {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.delete-action, .edit-action {
  cursor: pointer;
}

table {
  word-wrap: break-word;
}
