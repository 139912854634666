.email-popup {
    max-width:700px;
    width: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #f4f4f4;
    border-radius: 12px;
    border: 1px solid #ff4c4f;
    margin: auto;
    top: 50%;
    --webkit-transform: translateY(-50%) !important;
    --ms-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
}

.email-popup .modal-content {
    padding: 20px;
    margin:0;
    align-items: center;
    text-align: center;

}

.email-popup-content .left, .email-popup-content .right {
    width: 50%;
}

.email-popup-content .left-top {
    transform: translateX(-45px);
}

.email-popup-content .left-bottom-left {
    flex: 4;
}

.email-popup-content .left-bottom-right {
    flex: 3;
}

.email-popup .close-btn {
    color: var(--main-danger-color);
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: large;
}

.email-popup .img-container {
    position: relative;
    padding: 0;
}

.email-popup .special {
    position: absolute;
    top: 2px;
    left: 2px;
}

.email-popup .modal-sub-form {
    display: flex;
    /* width: 80%; */
    margin: auto;
    align-items: center;
    justify-content: center;
}

.email-popup .modal-sub-form > input {
    font-size: 13px;
    color: black;
    border: 1px solid #7c7c7c;
    border-radius: 0;
    padding: 5px;
    height: 39px;
}

.email-popup .modal-sub-form > button {
    font-size: 13px;
    color: white;
    background-color: var(--main-danger-color);
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 0;
    text-transform: uppercase;
}

.email-popup .share-container {
    margin-bottom: -20px;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.email-popup .share-btn {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.email-popup .share-btn a {
    color: #f69925;
    font-size: 23px;
}

@media (max-width: 767px) {
    .email-popup {
        width: 370px;
        max-width: 100vw;
    }
    .email-popup .modal-content {
        padding: 30px 20px;
    }
    .email-popup-content .left {
        display: none;
    }
    .email-popup-content .right {
        width: 100%;
    }
    .email-popup .close-btn {
        top: 5px;
        right: 5px;
        font-size: small;
    }    
}