.subscription__page {
  max-width: 400px;
  margin: 40px auto 0;
}

.subscription__wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.subscription__wrapper .subscription__header {
  text-align: center;
  font-size: 24px;
  line-height: 2;
  font-weight: 600;
}
.subscription__wrapper .input_field__wrapper {
  padding: 0;
  margin-block: 20px;
}

.btn--subscription {
  background-color: orangered !important;
  color: white !important;
  /* margin-inline: 10px; */
  /* max-width: 400px !important; */
}

.subscription__wrapper .input_field__wrapper,
.btn--subscription {
  width: 80% !important;
  margin-inline: auto !important;
}

.subscription__alert {
  max-width: 250px;
  margin-inline: auto;
  text-align: center;
}

@media (min-width: 800px) {
  .subscription__page {
    margin-top: 150px;
  }
  .subscription__alert {
    max-width: 400px;
  }
}
