.account-wrapper {
  width: 95%;
  margin: auto;
}
.account-wrapper .account-Container {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.account-wrapper .account-Container img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-top: 20px;
}
.account-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-details h4:nth-child(1) {
  font-weight: bolder;
}
.account-details span:nth-child(2) {
  color: #ff4c4f;
}

.account-Container .user-rating {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
}
.account-wrapper .account-Container .user-rating .row {
  flex: 0.3;
}
.account-wrapper .account-Container .user-rating .row span:nth-child(1) {
  font-weight: bolder;
}
.btnWrapper {
  width: 100%;
}
.account-wrapper .account-Container .user-rating .row:nth-child(1) {
  text-align: left;
}
.account-wrapper .account-Container .user-rating .row:nth-child(3) {
  text-align: right;
}

.editProfileWrapper {
  /* height: 100%; */
  margin-bottom: 20px;
}
.editProfileWrapper .input_field__wrapper {
  padding: 0;
  margin-block: 10px;
}

.editProfileInner {
  width: 95%;
  margin: auto;
}

.editProfileWrapper .btn--edit-profile {
  /* width: 130px; */
  width: 100%;
  height: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ff4c4f;
  border-radius: 8px;
  color: #ffffff;
  border: none;
}
.editProfileWrapper .btn--edit-profile:hover {
  background-color: #ff4c4f;
  opacity: 0.8;
}

@media (min-width: 700px) {
  .editProfileWrapper .btn--edit-profile {
    width: fit-content;
    min-width: max-content;
  }
}
