.popular-programs {
  /* background-color: #b2dafb; */
  padding: 100px 0;
}

.popular-programs .topper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.popular-programs .topper > h3 {
  text-transform: capitalize;
  font-weight: 500;
  color: #282828;
}

.popular-programs .topper > a {
  color: var(--main-danger-color);
  border: var(--main-danger-color) 1px solid;
  border-radius: 8px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: 0.3s ease-in;
}

.popular-programs .topper > a:hover {
  background-color: var(--main-danger-color);
  color: #fff;
}

.popular-programs .col {
  margin-bottom: 40px;
}

.popular-programs .card {
  box-shadow: 2px 2px 5px 2px rgba(194, 181, 181, 0.342);
  -webkit-box-shadow: 2px 2px 5px 2px rgba(194, 181, 181, 0.342);
  -moz-box-shadow: 2px 2px 5px 2px rgba(194, 181, 181, 0.342);
  position: relative;
}

.popular-programs .card .flag {
  position: absolute;
  right: 0%;
  bottom: 0%;
}

.popular-programs .card-body {
  padding: 5px 10px 0px;
  background-color: #f0efef;
}

.popular-programs a {
  color: #111111;
}

.popular-programs .card h4 {
  color: #282828;
  font-weight: 500;
  font-size: 16px;
  margin: 0px 0px 5px;
}

.popular-programs .total-discount {
  color: #282828;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}

.popular-programs .total-discount span {
  font-weight: lighter;
  color: #6c757d;
  font-size: 14px;
  font-style: italic;
}

.popular-programs .total-price {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  color: #282828;
  text-decoration: line-through;
}

.popular-programs .card-body {
  padding: 5px 10px 14px;
  background-color: #f0efef;
}
