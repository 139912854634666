.product-image {
  border-radius: 10%;
}

.cart-management-ui {
  width: 300px;
  max-height: 600px;
  overflow-y: auto;
}

.cart .product-item-image {
  width: 300px;
  height: 200px;
  border-radius: 10px;
}

.cart .product-item-image--container {
  margin-block: 10px;
}

.cart .dicount__tag {
  right: 10px;
  top: -15px;
  padding: 5px;
  border-radius: 5px;
}

/* Shopping List */
.cart_page {
  background-color: #f3f2f1;
}

.shopping__list {
  margin-bottom: 10px !important;
  margin-inline: 20px !important;
}
.shopping__list--item {
  background-color: white;
  width: 100%;
  margin-inline: 0.5rem;
  height: 440px;
}

.cart_page .row {
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

/* make paypal button inaccessible if the terms is not agreed upon */
.paypal__smart__buttons {
  position: relative;
  margin: 1rem;
  height: 180px;
}
.smart__hidden {
  position: absolute;
  inset: 0;
  z-index: 110;

  backdrop-filter: blur(3px) saturate(200%);
  -webkit-backdrop-filter: blur(3px) saturate(200%);
  background-color: rgba(17, 25, 40, 0.18);
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/* override the icon size */
.smart__hidden svg[data-icon="stop"] {
  font-size: 3rem;
}

/* override paypal buttons on mobile devices */
.paypal-buttons {
  min-width: unset;
}

/* mid tablet */
@media (min-width: 768px) {
  .cart_page {
    margin-left: 10px;
  }
  .cart_page .row {
    flex-wrap: unset;
    margin-right: 0;
    flex-direction: row;
  }

  .shopping__list--item {
    max-width: 300px;
    margin-inline: 0;
  }
}

/* tablet */
@media (min-width: 719px) {
  .shopping__list {
    justify-content: center;
  }
  .shopping__list--item {
    max-width: unset;
  }
  .paypal-buttons {
    min-width: 200px;
  }
}

/* desktop */
@media (min-width: 1219px) {
  .shopping__list {
    justify-content: start;
  }

  .shopping__list--item {
    max-width: 300px;
  }
}

.cart__summary {
  background-color: white;
}

.noscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noscrollbar::-webkit-scrollbar {
  display: none;
}

/* Program QRCode */
.programQRCode {
  background-color: white;
}

.programQRCode--wrapper {
  background-color: white;
  padding: 10px;
  border: 1px solid;
  border-radius: 20px;
}
