.admin-dashboard .container {
  width: 100%;
  max-width: 100% !important;
  background-color: #fff;
  padding: 0px 30px;
}
.admin-dashboard .admin-cards {
  display: flex;
  max-width: 242px;
  height: 132px;
  padding: 15px;
  flex-direction: row;
  align-items: center;
  border: none;
}
.lofty-admin-dashboard .admin-cards {
  position: relative;
}
.admin-dashboard .admin-cards .bottom-stats {
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  bottom: 16px;
  right: 16px;
  color: #42b145;
}

.admin-dashboard .anticon {
  font-size: 20px;
  margin: 5px;
}

.admin-dashboard .card-content span {
  display: block;
  margin-bottom: 2px;
  font-weight: 500;
  color: #242b27;
  font-size: 16px;
  font-family: "Maven Pro", sans-serif;
}
.admin-dashboard .card-content h4 {
  display: block;
  font-weight: 700;
  color: #242b27;
  font-size: 24px;
  font-family: "Maven Pro", sans-serif;
}

.admin-dashboard .admin-cards.earning {
  background-color: #c7ffc8;
}

.admin-dashboard .admin-cards.products {
  background-color: #abe0ff;
}

.admin-dashboard .admin-cards.programs {
  background-color: #fec3d1;
}

.admin-dashboard .admin-cards.users {
  background-color: #ffedb4;
}

.admin-dashboard .dropdown-btn {
  background-color: #ffffff;
  color: #111111;
}

.admin-dashboard .dropdown-item {
  font-size: 14px;
}
