.create-product-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0px 0px 10px;
}

.create-product-container .description-title {
  text-align: left;
  font-weight: bolder;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  margin-top: 10px;
}

.container-inner {
  width: 97%;
  margin: auto;
  margin-bottom: 80px;
}

.container-inner h6 {
  font-weight: bolder;
}

.productImageDiv #productImage {
  display: none;
}
.productImageDiv {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 10px 0px;
}
.container-inner .form-wrapper {
  width: 99%;
  margin: auto;
}
.productImageDiv label {
  height: 200px;
  border-radius: 10px;
  width: 98%;
  margin: auto;
  display: flex;
  border: 1px dashed rgba(49, 49, 49, 0.568);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.productImageDiv label span:nth-child(2) {
  font-weight: bolder;
}
.product-details-div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.select-Div {
  display: flex;
  width: 49%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
}
.select-Div select {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: rgba(0, 0, 0, 0.07);
  padding-left: 15px;
  margin-top: 10px;
  padding-right: 15px;
}

.create-item-input-container {
  margin-bottom: 15px;
}

.create-item-input-container label {
  display: flex;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
}

.create-item-input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  padding: 0px 10px;
  border-radius: 8px;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid gray;
}

.text-area {
  width: 100%;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid gray;
  outline: none;
}
.btnwrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.create-item-submit-btn {
  margin: 10px 0px 70px;
  min-height: 44px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 0px 35px;
  color: #ffffff;
  background-color: #ff4c4f;
}

.create-item-submit-btn:hover {
  background-color: #ff373a;
}

.create-item-error {
  color: #ff4c4f;
}

.ant-tooltip {
  font-size: 12px;
}

.complete-modal-content {
  gap: 20px;
}

.complete-modal-content button, .complete-modal-content a {
  width: calc(50% - 20px/2)
}

.complete-modal-content button.btn-primary {
  --bs-btn-bg: #ff4c4f;
  --bs-btn-border-color: #ff4c4f;
  --bs-btn-hover-bg: #ef3c3f;
  --bs-btn-hover-border-color: #ee3b3e;
  --bs-btn-active-bg: #ef3c3f;
  --bs-btn-active-border-color: #ee3b3e;
  --bs-btn-disabled-bg: #ff4c4f;
  --bs-btn-disabled-border-color: #ff4c4f;
}

.text-red {
  color:red
}