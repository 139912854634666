.referral-container {
  padding: 0 20px;
}

.referral-container h1 {
  font-size: 28px;
}

.referral-container table tbody td {
  font-size: 15px;
}
