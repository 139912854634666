.unredeemed-code-container {
  padding: 0px 20px;
}

.unredeemed-code-container h1 {
  font-size: 28px;
}

.unredeemed-code-container table tbody td {
  font-size: 15px;
}

.qr-reader {
  width: 300px;
  height: 300px;
}

.scanner-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#modal-body p {
  color: #282828;
  font-weight: 500;
}

#generator-container {
  display: flex;
  justify-content: center;
  width: clamp(250px, 100%, 350px);
}

.qr-modal .buttons {
  gap: 5px;
}

.qr-modal .btn-danger {
  --bs-btn-border-color: var(--main-danger-color);
  --bs-btn-hover-bg: var(--main-danger-color);
  --bs-btn-hover-border-color: var(--main-danger-color);
  --bs-btn-active-bg: var(--main-danger-color);
  --bs-btn-active-border-color: var(--main-danger-color);
  --bs-btn-disabled-color: var(--main-danger-color);
  --bs-btn-disabled-border-color: var(--main-danger-color);
}

.result {
  font-size: 16px;
}

.scanned-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.result-img-container img {
  width: 230px;
  height: 200px;
}

.result-details {
  margin-top: 8px;
}

.result-details h4 {
  color: #282828;
  font-size: 20px;
}

.result-details p {
  margin: 4px 0px 0px;
}

.result-details p span {
  font-weight: 400;
}

.result-details h5 {
  margin-top: 10px;
}

.result-details #description {
  font-weight: 400;
}

.result {
  font-size: 16px;
}

.scanned-result {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.result-img-container img {
  width: 230px;
  height: 200px;
}
