.public-nav {
  background-color: #fff;
  color: #000;
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  width: 100%;
  min-height: 70px;
  z-index: 3;
  box-shadow: rgb(220, 220, 220) 0px 2px 10px;
  -webkit-box-shadow: rgb(220, 220, 220) 0px 2px 10px;
  -moz-box-shadow: rgb(220, 220, 220) 0px 2px 10px;
}

.public-nav.home-nav {
  background-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.public-nav .container {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.public-nav .search-form-container {
  background-color: #fbfbfb;
  display: flex;
  padding: 0%;
  border-radius: 5px;
  border: 1px solid rgba(36, 43, 39, 0.1);
}

.public-nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0 !important;
}

.public-nav .nav-items {
  display: inline-block;
  margin: 10px;
}

.public-nav .nav-links {
  color: #000;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
}

.public-nav #logout-btn {
  color: #000;
}

.public-nav #logout-btn:hover {
  color: var(--main-danger-color);
}

.public-nav #dashboard {
  color: #000;
  border: #ffffff 1px solid;
  border-radius: 8px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: 0.3s ease-in;
}

.public-nav #dashboard:hover {
  background-color: #26292848;
}

.public-nav .mobile-toggler {
  display: none;
}

.public-nav .logo {
  margin: 10px;
}

.public-nav .logo > img {
  width: 100px;
}

.public-nav .nav-links:hover {
  color: var(--main-danger-color);
  padding-bottom: 15px;
  border-bottom: 1px solid var(--main-danger-color);
}

.toggle-btn {
  font-size: 30px;
}

.popover-content {
  padding: 0;
}

.public-nav .ant-popover-inner-content {
  padding: 0 !important;
}

.popover-content > li {
  list-style: none;
  padding: 10px 31px;
  margin: 0px;
  margin-left: -16px;
  margin-right: -16px;
}

.popover-content > li:hover {
  background-color: var(--main-danger-color);
  border-bottom: 1px solid var(--main-danger-color);
  color: #fff !important;
}

.popover-content > li:hover a {
  color: #fff !important;
}

.public-nav .user-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.public-nav .user-avatar .btn-outline-danger {
  --bs-btn-color: var(--main-danger-color);
  --bs-btn-border-color: var(--main-danger-color);
  --bs-btn-hover-bg: var(--main-danger-color);
  --bs-btn-hover-border-color: var(--main-danger-color);
  --bs-btn-active-bg: var(--main-danger-color);
  --bs-btn-active-border-color: var(--main-danger-color);
  --bs-btn-disabled-color: var(--main-danger-color);
  --bs-btn-disabled-border-color: var(--main-danger-color);
}

@media only screen and (max-width: 760px) {
  .public-nav nav {
    display: block;
    position: absolute;
    top: 70px;
    padding: 15px;
    background-color: rgba(36, 42, 49, 0.8);
    width: 80vw;
    transition: 0.3s linear;
    left: -120%;
  }

  .public-nav nav.show-nav {
    left: 50%;
    transform: translateX(-50%);
  }

  .public-nav nav.show-nav .nav-links {
    color: #fff;
  }

  .public-nav #logout-btn {
    color: #fff;
  }

  .public-nav ul {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .public-nav .mobile-toggler {
    display: inline-block;
  }

  .public-nav .nav-items {
    width: 100%;
  }

  .public-nav .nav-links {
    width: 100%;
    display: inline-block;
    text-align: center;
    border-bottom: 1px white solid;
    padding-bottom: 15px;
  }

  .public-nav .nav-links:hover {
    color: var(--main-danger-color) !important;
    border-bottom: 1px solid white;
  }

  .public-nav .user-avatar .btn-outline-danger {
    --bs-btn-color: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-bg: #f8f9fa;
    --bs-btn-hover-border-color: #f8f9fa;
    --bs-btn-active-bg: #f8f9fa;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-disabled-color: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-btn-active-color: #000;
    --bs-btn-hover-color: #000;
  }
}

.cart-badge {
  position: absolute;
  top: -16px;
}

.cart > span {
  position: relative;
}