.product-list-container {
  padding: 0px 20px;
}

.table thead {
  background-color: #242b27;
  color: rgb(241, 240, 240);
}

.table thead tr th {
  padding: 15px 4px;
}

.table tbody {
  font-size: 15px;
  text-transform: capitalize;
}

.borderless td,
.borderless th {
  padding: 20px 0px;
  border: none;
}

.borderless tr {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.delete-action {
  cursor: pointer;
  text-align: center;
}

table {
  word-wrap: break-word;
}

.confirm-msg {
  text-align: center;
  font-size: 20px;
  color: #e40d0d;
  font-weight: 500;
}

.search-container {
  width: 300px;
  margin: 30px 0px;
}

button.ant-btn.ant-btn-primary.ant-input-search-button {
  background-color: #212529;
}
