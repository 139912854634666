input {
  outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.search-button {
  outline: none;
  border: none;
  background-color: transparent;
}

.ant-select-selection-placeholder {
  color: black;
}

.program__search--wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  align-items: stretch;
}

@media (min-width: 800px) {
  .program__search--wrapper {
    gap: 0;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }

  .program__search--wrapper .input_field__wrapper .input_field,
  .program__search--wrapper .select__wrapper .select__input {
    display: flex;
    height: 60px;
    border-width: 0.3px;
    border-radius: 0;
  }

  .btn--search {
    border-radius: 0 !important;
    box-shadow: inset !important;
  }
  .program__search--wrapper .input_field {
    max-width: 150px;
    max-height: 100% !important;
  }
}

/* reset the custom form components */
.program__search--wrapper .select__input {
  margin: 0;
}
.program__search--wrapper .input_field__wrapper {
  padding: 0;
  gap: 0;
}

.program__search--wrapper .select__wrapper {
  margin-block: 0;
  padding: 0;
}

.btn--search {
  background-color: cornflowerblue !important;
  color: white !important;
  font-weight: 600 !important;
  min-width: 150px !important;
}
