.footer {
  background-color: #fff;
  padding-top: 80px;
  color: #222;

  text-align: center;
}

@media (min-width: 800px) {
  .footer {
    text-align: start;
  }
}

.footer ul {
  list-style: none;
  padding: 0;
  color: #5c5758;
}

.footer .title {
  color: #222;
  font-weight: bold;
}

.footer .container > p {
  text-align: center;
}

.footer .footer-links {
  color: #5c5758;
  margin-top: 15px;
}

.footer .footer-links:hover {
  color: var(--main-danger-color);
  font-weight: 500;
}

.footer .logo {
  width: 100px;
  margin-bottom: 20px;
}

.footer .footer-rights {
  margin-top: 1em;
}
