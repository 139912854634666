.product-listing {
  background-color: #dfdfdf;
  padding: 40px 0;
  text-align: center;
}

.product-listing .search-form {
  position: static;
  margin: 20px auto;
  transform: translate(0);
}

.product-listing .topper h4 {
  font-size: 15px;
  font-weight: 500;
}
.product-listing {
  background-color: #dfdfdf;
  padding: 40px 0;
}

.product-listing .topper {
  font-weight: bold;
  color: rgb(24, 23, 23);
}

.product-listing .topper h4 {
  font-size: 18px;
  text-transform: capitalize;
}

.product-listing .topper h4 span {
  font-weight: 400;
}

.product-listing .col {
  margin-bottom: 20px;
  text-align: left;
}

.product-listing .avatar-container {
  height: 220px;
}

.product-listing .avatar {
  object-fit: cover;
  object-position: top;
  height: 100%;
}

.product-listing .product-title {
  color: rgb(49, 49, 49);
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
  margin: 0px 0px 5px;
}

.product-listing a .product-type {
  color: rgb(49, 49, 49);
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
}

.product-listing a .product-discount {
  color: rgb(49, 49, 49);
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 0px;
}

.product-listing a .product-price {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  color: rgb(88, 88, 88);
  text-decoration: line-through;
}

.card {
  position: static;
}
